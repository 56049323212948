import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class FeatureMasterService {
    constructor() {
        this.headers = createHeaders();
    }

    async getAllFeatureList() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/feature_master`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async deleteFeature(id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/feature_master/${id}`;
            const response = await axios.delete(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


}


export default new FeatureMasterService();