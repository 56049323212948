import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class OrgFeatureService {
    constructor() {
        this.headers = createHeaders();
    }

    async CreateOrgFeature(object) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_feature`;
            const response = await axios.post(url,object,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async deleteOrgFeature(id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_feature/${id}`;
            const response = await axios.delete(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async UpdateOrgFeature(object) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_feature`;
            const response = await axios.put(url,object, this.headers);
            console.log("response in update feature",response)
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getOrgFeature() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_feature`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    async getOrgFeaturebyOrg(id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_feature_Org/${id}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    

}


export default new OrgFeatureService();