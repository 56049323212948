
import {  GET_PRODUCT_PLAN_LIST} from "./types";
import ProductPlanService from "../../services/product_plans.service";

export const getProductPlanList = () => async(dispatch) => {
    try {
      let res = await ProductPlanService.getAllProductPlan();
       if(res.status === 200){
        dispatch({
          type : GET_PRODUCT_PLAN_LIST,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };
 