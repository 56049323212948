import { GET_ALL_FEATURE,DELETE_FEATURE,CREATE_FEATURE,UPDATE_FEATURE,GET_FEATURE_BY_ID} from "../actions/types";

const initialState = {
  Feature_Master_List: null,
  
};

const Feature_Master = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case GET_ALL_FEATURE:
        return {
            ...state,
            Feature_Master_List: payload,
        };
        
        default:
          return state;
  }
};

export default Feature_Master;