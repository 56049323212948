import React, { useState, useEffect } from 'react';
import { Card, Link, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Select, MenuItem, Typography } from '@mui/material';
import { createTestCase, createTestSteps, getTestStepsList, deleteTestStep, updateTestSteps, getTestcasesById } from "../redux/actions/testcase";
import TextField from '@mui/material/TextField';
import { ACTION_CHOICES } from '../constants/actionTypes';
import { ENV_VAR } from '../common.js';
import { connect } from "react-redux";
import '../styles/project.css';
import '../styles/common.css';
import '../styles/custom.css';
import '../styles/files.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { modalPopUpStyleLarge, modalPopUpStyle } from '../styles/getLPTheme';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SaveIcon from '@mui/icons-material/Save';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Validations from './Validation';
import Attribute from './Attribute';
import UploadExcel from './Files/upload-excel';
import { toast } from 'react-toastify';
import Divider from "@mui/material/Divider";
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from "@mui/icons-material/Edit";
import { Padding } from '@mui/icons-material';
import ConditionStepTable from './ConditionStepTable.jsx';


const TestCasesTable = ({ createTestSteps, getTestStepsList, deleteTestStep, testCaseData, updateTestSteps, getTestcasesById }) => {
    const [newRowName, setNewRowName] = useState('');
    const [newRowStatus, setNewRowStatus] = useState('');
    const [showErrorName, setErrorName] = useState(false);
    const [showErrorActionType, setShowErrorActionType] = useState(false);
    const [stepsList, setStepsList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openValidation, setOpenValidation] = React.useState(false);
    const [openFileUploader, setOpenFileUploader] = React.useState(false);
    const [openUploader, setOpenUploader] = React.useState(false);
    const [testStepData, setTestStepData] = React.useState(false);
    const [forceRender, setForceRender] = useState(false);
    const [submitClicked, setSubmitClicked] = useState([]);
    const [openViewer, setOpenViewer] = React.useState(false);
    const [viewerUrl, setViewerUrl] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [isEditing, setIsEditing] = useState(false);


    useEffect(() => {
        if (testCaseData?.case_id) {
            getTestStepsList(testCaseData.case_id)
                .then((res) => {
                    const sortedStepsList = res?.data?.sort((a, b) => a.priority - b.priority);
                    setStepsList(sortedStepsList);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [testCaseData?.case_id]);


    const refreshTestStepList = () => {
        if (testCaseData?.case_id) {
            getTestStepsList(testCaseData.case_id)
                .then((res) => {
                    const sortedStepsList = res?.data?.sort((a, b) => a.priority - b.priority);
                    setStepsList(sortedStepsList);
                })
        }
        setOpenFileUploader(false);
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDragStart = (e, row) => {
        if (row.step_id) {
            e.dataTransfer.effectAllowed = 'move';
            e.dataTransfer.setData('text/plain', row.step_id);
            e.currentTarget.classList.add('dragging');
        }
    };

    const handleOpenValidation = (row) => {
        setTestStepData(row);
        setOpenValidation(true);
    };

    const handleOpenAttribute = (row) => {
        setTestStepData(row);
        setOpen(true);
    };

    const handleOpenUploader = (row) => {
        setTestStepData(row);
        setOpenFileUploader(true);

    };

    const handleViewer = (row) => {
        const url = `${ENV_VAR.API_HOST}/${row.f_path}`;
        const googleDocsUrl = `https://docs.google.com/viewer?url=${url}&embedded=true`;
        setViewerUrl(googleDocsUrl);
        setSelectedFile(row);
        setOpenViewer(true);
    };
    const handleCloseUploader = (row) => {
        setOpenFileUploader(false);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenUploader(false);
        setOpenViewer(false);
    };

    const handleCloseValidation = () => {
        setOpenValidation(false);
    };

    const truncateText = (text, maxLength) => {
        if (text?.length <= maxLength) {
            return text;
        }
        return text?.substring(0, maxLength) + '...';
    };

    const handleDrop = (e, targetRow) => {
        e.preventDefault();
        const draggedStepId = e.dataTransfer.getData('text/plain');
        const draggedRowIndex = stepsList.findIndex(row => row.step_id === draggedStepId);
        const targetRowIndex = stepsList.findIndex(row => row.step_id === targetRow.step_id);

        if (targetRow.step_id) {
            let successShown = false;

            if (draggedRowIndex !== -1 && targetRowIndex !== -1) {
                const updatedRows = [...stepsList];
                const [draggedRow] = updatedRows.splice(draggedRowIndex, 1);
                updatedRows.splice(targetRowIndex, 0, draggedRow);

                updatedRows.forEach((row, index) => {
                    row.priority = index + 1;
                });
                setStepsList(updatedRows);
                updatedRows.forEach((row, index) => {
                    let obj = {
                        id: row.step_id,
                        step_name: row.step_name,
                        step_action_type: row.step_action_type,
                        test_id: row.case_id,
                        priority: row.priority
                    };

                    updateTestSteps(obj).then(() => {
                        if (!successShown) {
                            toast.success("Test steps priority updated successfully");
                            successShown = true;
                        }
                    })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                });
            }
        }
        const draggedElement = document.querySelector('.dragging');
        if (draggedElement) draggedElement.classList.remove('dragging');
    };


    const handleStatusChange = (event, index) => {
        const updatedStepsList = [...stepsList];
        updatedStepsList[index].step_action_type = event.target.value;
        setStepsList(updatedStepsList);
        // if (event.target.value === 'Import_Test_Data_from_Excel') {
        //     setOpenFileUploader(true);
        // }
    };

    const handleAddRow = () => {
        setErrorName(false);
        setShowErrorActionType(false);
        const newRow = {
            id: stepsList.length + 1,
            name: newRowName.trim(),
            step_action_type: newRowStatus,
            index: stepsList.length
        };

        setStepsList([...stepsList, newRow]);
        setNewRowName('');
        setNewRowStatus('');
    };

    const handleStepNameChange = (event, index) => {
        const updatedStepsList = [...stepsList];
        updatedStepsList[index].step_name = event.target.value;
        updatedStepsList[index].name = event.target.value;
        setStepsList(updatedStepsList);
    };

    const deleteSteps = (index) => {
        const stepToDelete = stepsList[index];
        if (!stepToDelete.step_id) {
            const updatedStepsList = [...stepsList];
            updatedStepsList.splice(index, 1);
            setStepsList(updatedStepsList);
        } else {
            deleteTestStep(stepToDelete.step_id)
                .then(() => {
                    const updatedStepsList = [...stepsList];
                    updatedStepsList.splice(index, 1);
                    getTestcasesById(testCaseData.case_id);
                    setStepsList(updatedStepsList);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    const handleToggleEdit = () => {
        setIsEditing(prevState => !prevState); // Toggle the editing state
    };

    const onSubmit = (index) => {
        setForceRender(prevState => !prevState);
        if (!stepsList[index]?.step_id) {
            if (!stepsList[index].name || !stepsList[index].step_action_type) {
                // setShowErrorActionType(true);
                // setErrorName(true);
                toast.error("Step name and Action type cannot be empty")
                return;
            }
            else {
                // setShowErrorActionType(false);
                // setErrorName(false);
                let obj = {
                    step_name: stepsList[index].step_name,
                    step_action_type: stepsList[index]?.step_action_type,
                    test_id: testCaseData.test_id ? testCaseData.test_id : testCaseData.case_id,
                    priority: index + 1,
                }
                createTestSteps(obj)
                    .then((res) => {
                        stepsList[index] = res.data;
                        setForceRender(prevState => !prevState);
                        getTestcasesById(testCaseData.case_id);
                        toast.success("Test step created successfully");
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
                setSubmitClicked(false);
            }
        }

        if (stepsList[index]?.step_id) {
            if (!stepsList[index].step_name || !stepsList[index].step_action_type) {
                // setShowErrorActionType(true);
                // setErrorName(true);
                toast.error("Step name and Action type cannot be empty")
                return;
            } else {
                // setShowErrorActionType(false);
                // setErrorName(false);
                stepsList?.map(testStepData => testStepData.step_id === stepsList[index]?.step_id)
                let obj = {
                    id: stepsList[index].step_id,
                    step_name: stepsList[index].step_name,
                    step_action_type: stepsList[index].step_action_type,
                    test_id: testCaseData.test_id ? testCaseData.test_id : testCaseData.case_id,
                    priority: stepsList[index].priority
                }

                updateTestSteps(obj)
                    .then(() => {
                        getTestStepsList(stepsList[index]?.test_id)
                            .then((res) => {
                                const sortedStepsList = res?.data?.sort((a, b) => a.priority - b.priority);
                                setStepsList(sortedStepsList);
                                toast.success("Test steps updated successfully");
                            })
                            .catch((error) => {
                                console.error('Error:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                    });
            }
        }
    }

    return (
        <div className='marginTop'>
            <Card
                raised
                sx={{
                    minWidth: "100%",
                    overflowX: "auto"
                }}>
                <Typography variant="h5" sx={{
                    p: 2
                }}>
                    Add Teststeps for: <span className='textColor'><Tooltip title={testCaseData?.case_name}> {testCaseData?.case_name && truncateText(testCaseData?.case_name, 60)}</Tooltip></span>
                    {isEditing ? <Tooltip title="Add test step"><Button className='right' onClick={handleAddRow}> + Add step</Button></Tooltip>
                        : null}

                    <Button onClick={handleToggleEdit} style={{ minWidth: 'auto', borderRadius: '10%',backgroundColor: isEditing ? 'rgba(249, 95, 83, 0.2)' : 'rgba(142,145,149,0.2)',  }} className='right'>
                    <Tooltip title={isEditing ? "Cancel" : "Edit"} >
                        <span style={{ display: 'inline', alignItems: 'center', cursor: 'pointer' }}>
                        {isEditing ? <CancelIcon style={{ color: '#F95F53'}} /> : <EditIcon style={{ color: '#8e9195'}} />}
                        </span>
                    </Tooltip>
                    </Button>

                </Typography>
                <Divider></Divider>
                <CardContent style={{ pointerEvents: isEditing ? 'auto' : 'none', opacity: isEditing ? 1 : 0.7 }}>
                    <TableContainer className='dashboard-table table td'>
                        <Table>
                            <TableHead className='th'>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Action Type</TableCell>
                                    <TableCell>Attachment</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {stepsList?.map((row, index) => (
                                    <>
                                    <TableRow style={{ cursor: 'move' }}
                                        key={index} draggable onDragStart={(e) => handleDragStart(e, row)} onDragOver={handleDragOver} onDrop={(e) => handleDrop(e, row)}
                                    >
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell> <TextField
                                            type="text"
                                            value={row.step_name}
                                            onChange={(event) => handleStepNameChange(event, index)}
                                        />
                                            {/* {(showErrorName && stepsList[index].index === index) && <p style={{ color: 'red' }}>Step name cannot be empty</p>}  */}
                                        </TableCell>
                                        <TableCell>
                                            <Select className="form-control"
                                                value={row.step_action_type}
                                                onChange={(event) => handleStatusChange(event, index)}
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ height: '40px' }}
                                            >
                                                <MenuItem value={''}>Select actions</MenuItem>
                                                {ACTION_CHOICES.map((action, index) => (
                                                    <MenuItem value={action[0]} key={index}>{action[1]}</MenuItem>
                                                ))}
                                            </Select>
                                            {/* {(showErrorActionType && stepsList[index].index === index) && <p style={{ color: 'red' }}>Action type cannot be empty</p>} */}
                                        </TableCell>

                                        <TableCell>
                                            <Link onClick={() => handleViewer(row)}> {row.f_original_name}</Link>
                                        </TableCell>

                                        <TableCell className="action-icons">
                                            <Tooltip title="Save">
                                                <SaveIcon style={{ color: '#34B1AA' }} onClick={() => onSubmit(index)} className="action-icon" />
                                            </Tooltip>
                                            <Tooltip title="Attribute">
                                                {row.step_id && <FormatListBulletedIcon className="action-icon" onClick={() => handleOpenAttribute(row)} />}
                                            </Tooltip>
                                            <Tooltip title="Validation">
                                                {row.step_id && <PlaylistAddCheckIcon className="action-icon" onClick={() => handleOpenValidation(row)} />}
                                            </Tooltip>
                                            <Tooltip title="Upload file">
                                                {row.step_id && <UploadFileIcon onClick={() => handleOpenUploader(row)} className="action-icon" />}
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <DeleteIcon style={{ color: '#d71b1b' }} onClick={() => deleteSteps(index)} className="action-icon clickIconClassDelete" />
                                            </Tooltip>
                                        </TableCell>

                                    </TableRow>
                                    {(row.step_action_type === "IF" || row.step_action_type === "ELSE") && 
                                    <TableRow> <TableCell colSpan={5} style={{ width: "100%" }}>
                                    <Card style={{ width: "100%", padding: "16px" }}> 
                                     
                                    <ConditionStepTable  data={row}></ConditionStepTable> 
                                    </Card>
  </TableCell>
                                    </TableRow>
}
                                   </>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
                
                 
                    {isEditing ? <Tooltip title="Add test step"><Button className='right' onClick={handleAddRow} sx={{margin:"10px"}}> + Add step</Button></Tooltip>
                        : null}

                    <Button onClick={handleToggleEdit} sx={{margin:"10px"}} style={{ minWidth: 'auto', borderRadius: '10%',backgroundColor: isEditing ? 'rgba(249, 95, 83, 0.2)' : 'rgba(142,145,149,0.2)',  }} className='right'>
                    <Tooltip title={isEditing ? "Cancel" : "Edit"} >
                        <span style={{ display: 'inline', alignItems: 'center', cursor: 'pointer' }}>
                        {isEditing ? <CancelIcon style={{ color: '#F95F53'}} /> : <EditIcon style={{ color: '#8e9195'}}  />}
                        </span>
                    </Tooltip>
                    </Button>
                    
                 
                
            </Card>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <Attribute data={testStepData} refreshStepList={refreshTestStepList}></Attribute>
                    <div className="modal-footer">
                        <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openValidation}
                onClose={handleCloseValidation}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <Validations data={testStepData}></Validations>
                    <div className="modal-footer">
                        <Button onClick={handleCloseValidation} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>

            <Modal
                open={openFileUploader}
                onClose={handleCloseUploader}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyle, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <UploadExcel data={testStepData} refreshStepList={refreshTestStepList}></UploadExcel>
                    <div className="modal-footer">
                        <Button onClick={handleCloseUploader} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>


            <Modal
                open={openViewer}
                onClose={handleClose}
                aria-labelledby="viewer-modal-title"
                aria-describedby="viewer-modal-description">
                <Box component="form"
                    sx={{
                        ...modalPopUpStyleLarge, '& > :not(style)': { m: 1 },
                    }}
                    noValidate
                    autoComplete="off">
                    <div className="modal-header">
                        <h4>{selectedFile?.f_original_name}</h4>
                    </div>
                    <div className='viewer-container'>
                        <iframe
                            src={viewerUrl}
                            title="Document Viewer"
                            width="100%"
                            height="600px"
                        ></iframe>
                    </div>
                    <div className="modal-footer">
                        <Button onClick={handleClose} className="modalButton modalCloseButton">Close</Button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state) => ({
    testCaseData: state.auth.testCaseObject,
});

const mapDispatchToProps = {
    createTestCase,
    createTestSteps,
    getTestStepsList,
    deleteTestStep,
    updateTestSteps,
    getTestcasesById
};

export default connect(mapStateToProps, mapDispatchToProps)(TestCasesTable);
