
import {  GET_ORG_PLAN_BY_ORG,CREATE_ORG_PLAN,UPDATE_ORG_PLAN } from "./types";
import OrgPlanService from "../../services/user_plan.service";

export const CreateOrgPlan = (object) => async(dispatch) => {
  try {
    let res = await OrgPlanService.CreateOrgPlan(object);
     if(res.status === 200){
      dispatch({
        type : CREATE_ORG_PLAN,
        payload : res.data
      })
     }
  } catch (error) {
    console.error("error in login", error)
  }
};


export const getOrgPLanByOrgId = (id) => async(dispatch) => {
    try {
      let res = await OrgPlanService.getPlanByOrgId(id);
       if(res.status === 200){
       
        dispatch({
          type : GET_ORG_PLAN_BY_ORG,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };

  export const UpgradeOrgPlan = (object) => async(dispatch) => {
    try {
      let res = await OrgPlanService.UpdatePlan(object);
       if(res.status === 200){
       
        dispatch({
          type : UPDATE_ORG_PLAN,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };


 