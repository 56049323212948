import axios from "axios";
const Org_Feature = JSON.parse(localStorage.getItem('Feature'))

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["x-api-key"] = `${token}`;
  } else {
    delete axios.defaults.headers.common["x-api-key"];
  }
};

export const isFeaturePresent = (featureName) => {
  // const Feature = JSON.parse(localStorage.getItem('Feature'))

  const Feature = Org_Feature
  if(Feature){
    return Feature?.some((feature) => feature.name === featureName);
  }
  return true;
};
export const getFeatureValue = (featureName) => {
  const features = Org_Feature || [];
  const feature = features?.find((feature) => feature.name === featureName);
  if(features?.length>0){
    return feature ? feature.value : 0;

  }
  else{
    return 10;
  }
  
};


 