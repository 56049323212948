
import {  GET_PRODUCT_PLAN,GET_FEATURE_BY_PLAN,DELETE_PLAN_FEATURE,CREATE_PRODUCT_PLAN } from "./types";
import PlanFeatureService from "../../services/plan_feature.service";

export const CreatePlanFeature = (object) => async(dispatch) => {
  try {
    let res = await PlanFeatureService.AddPlanFeature(object);
     if(res.status === 200){
      dispatch({
        type : CREATE_PRODUCT_PLAN,
        payload : res.data
      })
     }
  } catch (error) {
    console.error("error in login", error)
  }
};


export const getPlanFeature = () => async(dispatch) => {
    try {
      let res = await PlanFeatureService.getPlanFeatureList();
       if(res.status === 200){
       
        dispatch({
          type : GET_PRODUCT_PLAN,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };

  export const getFeaturebyPlan = (id) => async(dispatch) => {
    try {
      let res = await PlanFeatureService.getPlanFeatureByProduct(id);
       if(res.status === 200){
       
        dispatch({
          type : GET_FEATURE_BY_PLAN,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };

  export const deletePlanFeature = (id) => async(dispatch) => {
    try {
      let res = await PlanFeatureService.deletePlanFeature(id);
       if(res.status === 200){
       
        dispatch({
          type : DELETE_PLAN_FEATURE,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };
 