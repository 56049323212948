import { CREATE_ORG_FEATURE,GET_ORG_FEATURE,DELETE_ORG_FEATURE,UPDATE_ORG_FEATURE,GET_ORG_FEATURE_BY_ORG } from "../actions/types";

const initialState = {
  OrgFeatureList: null,
  Current_Org_Feature:null
  
  
};

const OrgFeature = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case CREATE_ORG_FEATURE:
        return {
            ...state,
            isCreated:true,
        };
    case GET_ORG_FEATURE:
        return {
            ...state,
            OrgFeatureList: payload,
        };
    case DELETE_ORG_FEATURE:
        return {
            ...state,
            isDeleted: true,
        };
    case UPDATE_ORG_FEATURE:
        return {
            ...state,
            OrgFeatureList: payload,
        };
    case GET_ORG_FEATURE_BY_ORG:
        return {
            ...state,
            Current_Org_Feature: payload,
        };
    
        
        default:
          return state;
  }
};

export default OrgFeature;
