export const LOGIN_SUCCESS = "login-success";
export const PROJECT_LIST = "project-list";
export const PROJECT_LIST_APP = "project-list_app";
export const CREATE_PROJECT = "create-project";
export const UPDATE_PROJECT = "update_project";
export const GET_TESTCASE = "get_testcase";
export const CREATE_TESTCASE = "create_testcase";
export const DELETE_TEST_CASE = "delete_testcase";
export const UPDATE_TESTCASE = "update_testcase";
export const GET_TEST_STEP_LIST = "get_test_step_list";
export const CLEAR_TEST_STEP_LIST = "clear_test_step_list";
export const UPDATE_TEST_STEP = "update_test_step";
export const UPDATE_FILE_IN_TEST_STEP = "update_file_in_test_step";
export const CREATE_TEST_STEPS = "create_test_steps";
export const DELETE_TEST_STEPS = "delete_test_step_list";
export const TEST_STEP_CONDITION_LIST = "test_step_condition_list";
export const GET_ATTRIBUTES = "get_attributes";
export const CREATE_ATTRIBUTES = "create_attributes";
export const UPDATE_ATTRIBUTES = "update_attributes";
export const DELETE_ATTRIBUTES = "delete_attributes";
export const GET_VALIDATIONS = "get_validations";
export const CREATE_VALIDATIONS = "create_validations";
export const UPDATE_VALIDATIONS = "update_validations";
export const DELETE_VALIDATIONS = "delete_validations";
export const GET_ALL_TESTCASES = "get_all_testcases";
export const CLEAR_ATTRIBUTES = "clear_attributes";
export const CLEAR_VALIDATIONS = "clear_validations";
export const GET_TEST_CASE_BY_ID = "get_test_case_by_id"; 
export const CLEAR_TESTCASE_INFO = "clear_testcase_info";
export const RUN_JOB_DATA = "run_job_data";
export const CREATE_SUITES = "create_suites";
export const GET_SUITES = "get_suites";
export const UPDATED_SUITES = "updated_suites";
export const DELETE_SUITES = "delete_suites";
export const ALL_TEST_LIST_FOR_SUITES = "all_test_list_for_suites";
export const CREATE_TEST_SUITE_CASE = "create_test_suite_case";
export const GET_SUITES_TESTCASES = "get_suites_testcase";
export const GET_SUITES_TESTCASES_BY_ID = "get_suites_testcase_by_id";
export const UPDATED_SUITES_TESTCASES = "updated_suites_testcases";
export const DELETE_SUITE_TESTCASES = "delete_suites_testcases";
export const GET_TEMPLATES = "get_templates";
export const ADD_TEMPLATES = "add_templates";
export const GET_ATTRIBUTES_FOR_EXPORT = "get_attributes_for_export";
export const GET_VALIDATIONS_FOR_EXPORT = "get_validations_for_export";
export const GET_TEST_STEPS_FOR_EXPORT = "get_test_steps_for_export";
export const CLEAR_ATTRIBUTES_LIST_EXPORT = "clear_attribute_list_export";
export const CLEAR_VALIDATION_LIST_EXPORT = "clear_validation_list_export";
export const CLEAR_TESTSTEPS_LIST_EXPORT = "clear_teststeps_list_export";
export const RUN_SUITES = "run_suites";
export const ASSIGN_PROJECTS_TO_USERS = "assign_project_to_users";
export const GET_PROJECTS_FOR_USERS = "get_projects_for_users";
export const UPDATE_PROJECTS = "update_projects";
export const GET_ALL_USERS_LIST = "get_all_users_list";
export const GET_PROJECTS_BY_USER_ID = "get_projects_by_user_id";
export const DELETE_PROJECTS_BY_USER_ID = "delete_project_by_user_id";
export const CLEAR_PROJECT_LIST_BY_USER = "clear_project_by_user_id";
export const GET_CURRENT_USER_DETAILS = "get_current_user_deatails";
export const GET_CRONTAB_LIST = "get_crontab_list";
export const UPDATE_CRONTAB = "update_crontab";
export const CREATE_CRONTAB = "create_crontab";
export const DELETE_CRONTAB = "delete_crontab";
export const GET_CLOCKED_LIST = "get_clocked_list";
export const UPDATE_CLOCK_SCHEDULE = "update_clock_schedule";
export const CREATE_CLOCK_SCHEDULE = "create_clock_schedule";
export const DELETE_CLOCK_SCHEDULE = "delete_clock_schedule";
export const GET_FILES_LIST = "get_files_list";
export const REPLACE_FILE = "replace_file";
export const UPLOAD_FILE = "upload_file";
export const DELETE_FILE = "delete_file";
export const CHECK_DUPLICATE = "CHECK_DUPLICATE";

export const CREATE_AI_TEST = "create_ai_test";

export const LOGIN_FAIL = "login-fail";
export const LOGOUT = "logout";
export const AUTH_USER = "auth-user";
export const SHOW_LOADER = "show-loader";
export const HIDE_LOADER = "hide-loader";
export const SET_MESSAGE = "set-message";
export const CLEAR_MESSAGE = "clear-message";
export const CONTRACTS_LIST_SUCCESS = "contracts-list/success";
export const CONTRACTS_LIST_FAIL = "contracts-list/fail";
export const CONTRACTS_FILTER = "contracts/dropdown-filter";
export const WORKER_COUNTRY_FILTER = "workers/country-dropdown-filter";
export const WORKER_NAME_FILTER = "workers/name-textbox-filter";
export const SET_TOP_WORKER_TAB = "workers/top-tab";
export const ACTION_STATUS_FILTER = "action/status-dropdown-filter";
export const SET_USER_DATA = "set-user-account-info";

export const UPLOAD_ACCOUNT_LOGO = "UPLOAD_ACCOUNT_LOGO";
export const GET_ACCOUNT_LOGO = "GET_ACCOUNT_LOGO";

export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS";
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL";
export const SAVE_USER_PROFILE_SUCCESS = "SAVE_USER_PROFILE_SUCCESS";
export const SAVE_USER_PROFILE_FAIL = "SAVE_USER_PROFILE_FAIL";

export const GET_USER_ACCOUNT_SUCCESS = "GET_USER_ACCOUNT_SUCCESS";
export const GET_USER_ACCOUNT_FAIL = "GET_USER_ACCOUNT_FAIL";
export const SAVE_USER_ACCOUNT_SUCCESS = "SAVE_USER_ACCOUNT_SUCCESS";
export const SAVE_USER_ACCOUNT_FAIL = "SAVE_USER_ACCOUNT_FAIL";

export const ADD_CONTRACTS_SUCCESS = "contracts/add/success";
export const ADD_CONTRACTS_FAIL = "contracts/add/fail";

export const RENEW_CONTRACTS_SUCCESS = "contracts/renew/success";
export const RENEW_CONTRACTS_FAIL = "contracts/renew/fail";

export const SET_CONT_NEW_CLONE =
  "contracts/handle-wizard/add-new-or-clone/set";
export const CLEAR_CONT_NEW_CLONE =
  "contracts/handle-wizard/add-new-or-clone/clear";

export const SET_ADD_NEW_CONT_PAYLOADS = "contracts/add-new-payload/set";
export const CLEAR_ADD_NEW_CONT_PAYLOADS = "contracts/add-new-payload/clear";

export const JOB_ORDER_SUCCESS = "contracts/job-order/success";
export const JOB_ORDER_FAIL = "contracts/job-order/fail";
export const JOB_ORDER_CLEAR = "JOB_ORDER_CLEAR";

export const UPDATE_CONT_DETAILS_SUCCESS = "contracts-details/update-success";
export const UPDATE_CONT_DETAILS_FAIL = "contracts-details/update-fail";

export const FINAL_UPDATE_CONT_DETAILS = "contracts-details/final-update";

export const CONT_WORKSITE_LIST_SUCCESS = "contracts/worksites/success";
export const CONT_WORKSITE_LIST_FAIL = "contracts/worksites/fail";

export const CONT_HOUSING_LIST_SUCCESS = "contracts/housing/success";
export const CONT_HOUSING_LIST_FAIL = "contracts/housing/fail";

export const CONT_VEHICLE_LIST_SUCCESS = "contracts/vehicle/success";
export const CONT_VEHICLE_LIST_FAIL = "contracts/vehicle/fail";

export const CONT_PIECERATE_LIST_SUCCESS = "contracts/piecerate/success";
export const CONT_PIECERATE_LIST_FAIL = "contracts/piecerate/fail";

export const CONT_PETITION_LIST_SUCCESS = "contracts/petition/success";
export const CONT_PETITION_LIST_FAIL = "contracts/petition/fail";

export const US_APPLICANT_LIST_SUCCESS = "contracts/us-applicant/success";
export const US_APPLICANT_LIST_FAIL = "contracts/us-applicant/fail";

export const US_APPLICANT_UPDATE_SUCCESS =
  "contracts/us-applicant/update/success";
export const US_APPLICANT_UPDATE_FAIL = "contracts/us-applicant/update/fail";

export const ACTIONS_LIST_SUCCESS = "action-items/success";
export const ACTIONS_LIST_FAIL = "action-items/fail";

export const ADD_ACTIONS_SUCCESS = "action-items/add/success";
export const ADD_ACTIONS_FAIL = "action-items/add/fail";

export const SET_TOP_TAB = "contracts/job-order/top-tab";
export const SET_LEFT_TAB = "contracts/job-order/left-tab";

export const SET_MODAL_DETAILS = "set-modal-details";
export const CLEAR_MODAL_DETAILS = "clear-modal-details";

// Set when click on any row of grid like contract, workers list
export const SET_CLICKED_INDEX = "set-click-index-of-list-item";
export const SET_CLICKED_INDEX_MODEL = "set-click-index-of-list-item-model";
export const SET_SELECTED_ROWS = "set-unique-selected-ids-of-list-item";
export const SET_SELECTED_BRANCH = "set-selected-branch-id";
export const CLEAR_SELECTED_BRANCH = "clear-selected-branch-id";
export const SET_SELECTED_BRANCH_NAME = "set-selected-branch-name";

export const ALREADY_ADDED = "already-added-from-roaster";

export const SET_TOP_ASSETS_TAB = "assets/top-tab";

export const ASSETS_LIST_SUCCESS = "assets-list/success";
export const ASSETS_LIST_FAIL = "assets-list/fail";

export const ADD_ASSETS_SUCCESS = "assets/add/success";
export const ADD_ASSETS_FAIL = "assets/add/fail";

export const UPDATE_ASSETS_SUCCESS = "assets/update/success";
export const UPDATE_ASSETS_FAIL = "assets/update/fail";

export const SET_PLAN_TYPE = "set-plan-type";
export const SET_PLAN_NAME = "set-plan-name";

export const ASSIGN_TO_CONTRACTS_SUCCESS = "assing-to-contract/success";
export const ASSIGN_TO_CONTRACTS_FAIL = "assing-to-contract/fail";

export const MARK_PRIMARY_SUCCESS =
  "mark-housing-or-worksite-as-primary/success";
export const MARK_PRIMARY_FAIL = "mark-housing-or-worksite-as-primary/fail";

export const UPDATE_ADD_COUNTIES_SUCCESS =
  "worksite/update/addtional-counties/success";
export const UPDATE_ADD_COUNTIES_FAIL =
  "worksite/update/addtional-counties/fail";

export const WORKERS_LIST_SUCCESS = "workers-list/success";
export const WORKERS_LIST_FAIL = "workers-list/fail";

export const ADD_WORKERS_SUCCESS = "workers/add/success";
export const ADD_WORKERS_FAIL = "workers/add/fail";

export const EDIT_PHONE_SUCCESS = "workers/edit-phone/success";
export const EDIT_PHONE_FAIL = "workers/edit-phone/fail";

export const MARK_INELIGIBLE_SUCCESS = "workers/mark-eneligible/success";
export const MARK_INELIGIBLE_FAIL = "workers/mark-eneligible/fail";

export const ASSIGN_JOB_ORDER_SUCCESS = "workers/assign-job-order/success";
export const ASSIGN_JOB_ORDER_FAIL = "workers/assign-job-order/fail";

export const DOWNLOAD_FRMT_SUCCESS = "download-excel-format/success";
export const DOWNLOAD_FRMT_FAIL = "download-excel-format/fail";

export const BULK_UPLOAD_SUCCESS = "bulk-upload/success";
export const BULK_UPLOAD_FAIL = "bulk-upload/fail";

export const SERVICE_ELECTION = "contracts-service-election";
export const CONTRACT_TYPE = "contracts-type";

export const GET_WORKERS_BY_WORK_TYPE_SUCCESS =
  "GET_WORKERS_BY_WORK_TYPE_SUCCESS";
export const GET_WORKERS_BY_WORK_TYPE_FAIL = "GET_WORKERS_BY_WORK_TYPE_FAIL";

export const SAVE_ASSIGN_WORKERS_LIST_SUCCESS =
  "SAVE_ASSIGN_WORKERS_LIST_SUCCESS";
export const SAVE_ASSIGN_WORKERS_LIST_FAIL = "SAVE_ASSIGN_WORKERS_LIST_FAIL";
export const GET_ASSIGN_WORKERS_LIST_SUCCESS =
  "GET_ASSIGN_WORKERS_LIST_SUCCESS";
export const GET_ASSIGN_WORKERS_LIST_FAIL = "GET_ASSIGN_WORKERS_LIST_FAIL";
export const CLEAR_ASSIGN_WORKERS_LIST = "CLEAR_ASSIGN_WORKERS_LIST";

export const GET_DOC_CAT_SUCCESS = "GET_DOC_CAT_SUCCESS";
export const GET_DOC_CAT_FAIL = "GET_DOC_CAT_FAIL";
export const CLEAR_DOC_CAT = "CLEAR_DOC_CAT";

export const GET_AUDIT_FILES_SUCCESS = "GET_AUDIT_FILES_SUCCESS";
export const GET_AUDIT_FILES_FAIL = "GET_AUDIT_FILES_FAIL";

export const UPLOAD_DOC_SUCCESS = "UPLOAD_DOC_SUCCESS";
export const UPLOAD_DOC_FAIL = "UPLOAD_DOC_FAIL";

export const GET_TEST_JOB = "get_test_jobs";
export const GET_SUITE_JOB = "get_suite_jobs";
export const GET_SUITECASE_JOB = "get_suite_Case_jobs";
export const GET_DASHBOARD = "get_dashboard";
export const GET_SUITE_LIST = "get_suite_list";
export const SUITE_EXECUTION_REPORT ="get_suite_execution_report"


export const USER_LIST = "user_list";
export const CREATE_USER = "create_user";
export const UPDATE_USER = "update_user";
export const DELETE_USER = "delete_user";
export const USER_INFO="user_info";


//Schedular
export const SCHEDULAR_LIST = "schedular_list";
export const CREATE_SCHEDULAR = "create_schedular_user";
export const UPDATE_SCHEDULAR = "update_schedular_user";
export const DELETE_SCHEDULAR = "delete_schedular_user";
export const GET_SCHEDULAR_BY_ID = "get_schedular_by_id";
export const GET_DASHBOARD_BY_ID = "get_dashboard_by_id";

export const STOP_JOB = "stop_running_job";

export const SCHEDULAR_REPORT_LIST ="GET_SCHEDULAR_REPORT_LIST"
export const CREATE_JIRA_TICKET ="CREATE_JIRA_TICKET";
export const GET_JIRA_LIST="GET_JIRA_LIST";
export const NOTIFICATION_LIST = "notification_list";
export const CREATE_JIRA_TOKEN ="CREATE_JIRA_TOKEN";
export const JIRA_TOKEN_LIST="JIRA_TOKEN_LIST";
export const DELETE_JIRA_TOKEN="DELETE_JIRA_TOKEN";
export const UPDATE_JIRA="UPDATE_JIRA";

export const CREATE_ENVIROMENT ="CREATE_ENVIROMENT";
export const ENVIROMENT_LIST="ENVIROMENT_LIST";
export const DELETE_ENVIROMENT="DELETE_ENVIROMENT";
export const UPDATE_ENVIROMENT="UPDATE_ENVIROMENT";

export const ADD_USER_TO_TESTCASE ="ADD_USER_TO_TESTCASE"
export const ASSIGN_USER_TO_TESTCASE ="ADD_USER_TO_TESTCASE"
export const ASSIGNED_USER_LIST_TO_TESTCASE ="ASSIGNED_USER_LIST_TO_TESTCASE"
export const USER_BY_PROJECT_ID ="USER_BY_PROJECT_ID"

export const GET_PERSONA_LIST = "GET_PERSONA_LIST"
export const CREATE_PERSONA ="CREATE_PERSONA";
export const DELETE_PERSONA ="DELETE_PERSONA";
export const UPDATE_PERSONA ="UPDATE_PERSONA";
export const ENV_PERSONA ="ENV_PERSONA";
export const RESET_ENV_PERSONA ="RESET_ENV_PERSONA";
export const FETCH_JIRA ="FETCH_JIRA";
export const FETCH_JIRA_EPIC_WISE ="FETCH_JIRA_EPIC_WISE";
export const JIRA_AI_TEST ="JIRA_AI_TEST";
export const RESET_AI_TEST ="RESET_AI_TEST";
export const JIRA_UAT_AI_TEST="JIRA_UAT_AI_TEST";

export const GET_PRODUCT_PLAN ="GET_PRODUCT_PLAN";
export const CREATE_PRODUCT_PLAN ="CREATE_PRODUCT_PLAN";
export const GET_PRODUCT_PLAN_LIST ="GET_PRODUCT_PLAN_LIST";
export const GET_FEATURE_BY_PLAN ="GET_FEATURE_BY_PLAN";
export const DELETE_PLAN_FEATURE ="DELETE_PLAN_FEATURE";

export const GET_ALL_FEATURE="GET_ALL_FEATURE";
export const DELETE_FEATURE="DELETE_FEATURE";
export const CREATE_FEATURE="CREATE_FEATURE";
export const UPDATE_FEATURE="UPDATE_FEATURE";
export const GET_FEATURE_BY_ID ="GET_FEATURE_BY_ID";

export const GET_ORG_BY_ID ="GET_ORG_BY_ID";
export const GET_ALL_ORG ="GET_ALL_ORG";

export const GET_ORG_PLAN_BY_ORG = "GET_ORG_PLAN_BY_ORG";
export const CREATE_ORG_PLAN = "CREATE_ORG_PLAN";


export const CREATE_ORG_FEATURE = "CREATE_ORG_FEATURE";
export const GET_ORG_FEATURE = "GET_ORG_FEATURE";
export const DELETE_ORG_FEATURE = "DELETE_ORG_FEATURE";
export const UPDATE_ORG_PLAN = "UPDATE_ORG_PLAN";
export const UPDATE_ORG_FEATURE = "UPDATE_ORG_FEATURE";
export const GET_ORG_FEATURE_BY_ORG = "GET_ORG_FEATURE_BY_ORG";


