
import {  GET_ALL_ORG,GET_ORG_BY_ID } from "./types";
import OrganisationService from "../../services/org.service";

export const get_Org_by_Id = (id) => async(dispatch) => {
  try {
    let res = await OrganisationService.getOrgById(id);
     if(res.status === 200){
      dispatch({
        type : GET_ORG_BY_ID,
        payload : res.data
      })
     }
  } catch (error) {
    console.error("error in login", error)
  }
};


export const getAllOrgs = () => async(dispatch) => {
    try {
      let res = await OrganisationService.getOrgList();
       if(res.status === 200){
       
        dispatch({
          type : GET_ALL_ORG,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };


 