import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class OrganisationService {
    constructor() {
        this.headers = createHeaders();
    }

    async getOrgList() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/org`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getOrgById(id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/org/${id}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }



}


export default new OrganisationService();