import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Card, CardContent, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, IconButton, Divider, Checkbox } from '@mui/material';
import {

    TextField,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { getProductPlanList } from '../redux/actions/product_plan';
import { getPlanFeature, deletePlanFeature, CreatePlanFeature } from '../redux/actions/plans_feature';
import { getAll_Feature } from '../redux/actions/feature_master';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}><Typography>{children}</Typography></Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const Plans = ({ getProductPlanList, Product_List, getPlanFeature, Plan_List, getAll_Feature, Feature_DataList, deletePlanFeature, CreatePlanFeature }) => {
    const [Datalist, setDataList] = useState([]);
    const [AllFeature, setAllFeature] = useState([]);
    const [FeatureList, setFeatureList] = useState([]);
    const [PlanId, setPlanId] = useState();
    const [value, setValue] = useState(0);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [open, setOpen] = useState(false);
    const [Filtered_Feature, setFiltered_Feature] = useState([]);
    const [featureValues, setFeatureValues] = useState({});
    const [featureErrors, setFeatureErrors] = useState({});
    useEffect(() => {
        getProductPlanList();
        getPlanFeature();
        getAll_Feature();

    }, []);

    useEffect(() => {
        if (Product_List) {
            setDataList(Product_List);
            const firstid = Product_List[value]?.id;
            setPlanId(firstid);
        }
    }, [Product_List, value]);


    useEffect(() => {
        if (Feature_DataList) {
            setAllFeature(Feature_DataList);

        }
    }, [Feature_DataList])

    useEffect(() => {
        if (Plan_List && PlanId && Datalist) {
            const selectedPlan = Plan_List?.filter((item) => item.plan_id === Datalist[value]?.id && item.plan_id === PlanId);
            setFeatureList(selectedPlan);
        }
    }, [Plan_List, PlanId, Datalist, value]);


    const handleChange = (event, newValue) => {
        setValue(newValue);
        setPlanId(Datalist[newValue]?.id);
    };

    const handelOpenModal = () => {
        const filterData = AllFeature.filter(
            (item) => !FeatureList.some(feature => feature.feature_id === item.id));
        setFiltered_Feature(filterData);
        setSelectedFeatures([]);
        setOpen(true);
    };
    const handel_delete_feature = (feature) => {
        deletePlanFeature(feature.id).then((res) => {
            getPlanFeature();
        }).catch((err) => {
            console.error(err);
        });

    }
    console.log(featureValues)
    // const handleCheckboxChange = (feature) => {
    //     setSelectedFeatures((prevSelected) =>
    //         prevSelected.includes(feature)
    //             ? prevSelected.filter((f) => f !== feature)
    //             : [...prevSelected, feature]
    //     );
    // };

    const handleAddSelectedFeatures = () => {
        for (let item of selectedFeatures) {
            const object = {
                plan_id: PlanId,
                feature_id: item.id,
                description: item.description,
                is_active: item.is_active,
                value: featureValues?.[item.id] ? featureValues?.[item.id] : ""
            };
            console.log(object);
            CreatePlanFeature(object)
                .then((res) => {
                    getPlanFeature();
                }).catch((err) => {
                    console.error(err);
                })

        }

        setOpen(false);
        setSelectedFeatures([]);
        setFeatureValues({});
    };


    const handleCheckboxChange = (feature) => {
        setSelectedFeatures((prev) =>
            prev.some(f => f.id === feature.id)
                ? prev.filter(f => f.id !== feature.id)
                : [...prev, feature]
        );
        setFeatureValues((prev) => {
            if (prev[feature.id]) {
                const updatedValues = { ...prev };
                delete updatedValues[feature.id];
                return updatedValues;
            }
            return prev;
        });
    };

    const handleFeatureValueChange = (id, value) => {
        const numericValue = Number(value);

        setFeatureValues((prev) => ({
            ...prev,
            [id]: value // Allow empty value temporarily
        }));

        setFeatureErrors((prev) => ({
            ...prev,
            [id]: value === "" || numericValue < 1 ? "Value should be greater than 0" : "" // Error if empty or <1
        }));
    };
    const isAddButtonDisabled = selectedFeatures.some(f => featureErrors[f.id]);
    // const handleFeatureValueChange = (id, value) => {
    //     setFeatureValues((prev) => ({
    //         ...prev,
    //         [id]: value
    //     }));
    // };

    return (
        <Card>
            <CardContent>
                <Typography variant="h5">Plans and Features</Typography>
            </CardContent>
            <Divider />
            <CardContent>
                <Box sx={{ width: '100%' }}>
                    <Tabs value={value} onChange={handleChange} centered>
                        {Datalist.map((plan, index) => (
                            <Tab key={plan.id} label={plan.name} />
                        ))}
                    </Tabs>
                    <Divider />
                    <TabPanel>
                        <Card sx={{ width: "100%", padding: 2, boxShadow: 3 }}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    {FeatureList?.length > 0 ? (
                                        FeatureList.map((feature, idx) => (
                                            <Grid item xs={12} sm={6} md={4} key={idx}>
                                                <Box
                                                    sx={{
                                                        padding: 2,
                                                        border: "1px solid #ddd",
                                                        borderRadius: "4px",
                                                        boxShadow: 1,
                                                        textAlign: "center",
                                                        backgroundColor: "#f9f9f9",
                                                        position: "relative",
                                                    }}
                                                >
                                                    <IconButton
                                                        sx={{ position: "absolute", top: "4px", right: "4px", zIndex: 1 }}
                                                        size="small" onClick={() => handel_delete_feature(feature)}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                    <Typography color="textPrimary">{feature.feature_name} {feature.value ? `- ${feature.value}` : ""}</Typography>
                                                </Box>
                                            </Grid>
                                        ))
                                    ) : (
                                        <Typography>No features available.</Typography>
                                    )}
                                </Grid>
                            </CardContent>
                        </Card>
                        <Divider />
                        <Button color="primary" variant="text" onClick={handelOpenModal}>
                            +Add Feature
                        </Button>
                    </TabPanel>


                </Box>
            </CardContent>

            <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                <DialogTitle>Available Features to Add</DialogTitle>
                <DialogContent>
                    <TableContainer >
                        <Table >
                            <TableHead>
                                <TableRow>
                                    <TableCell >Select</TableCell>
                                    <TableCell >Feature</TableCell>

                                    <TableCell >Value</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Filtered_Feature?.map((feature, index) => (
                                    <TableRow key={index}>

                                        <TableCell >
                                            <Checkbox
                                                checked={selectedFeatures.some(f => f.id === feature.id)}
                                                onChange={() => handleCheckboxChange(feature)}
                                            />
                                        </TableCell>
                                        <TableCell >{feature.name}</TableCell>
                                        <TableCell>
                                            {feature.is_value && (

                                                <TextField
                                                    type="number"
                                                    variant="outlined"
                                                    value={featureValues[feature.id] || ""}
                                                    onChange={(e) => handleFeatureValueChange(feature.id, e.target.value)}
                                                    placeholder="Add Value"
                                                    disabled={!selectedFeatures.some(f => f.id === feature.id)}
                                                    error={!!featureErrors[feature.id]} // Show error styling if there's an error
                                                    helperText={featureErrors[feature.id] || ""} // Show error message
                                                />

                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddSelectedFeatures}
                        disabled={isAddButtonDisabled || selectedFeatures.length === 0} 
                    >
                        Add Selected Features
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    Product_List: state?.Product_Plans?.Product_List?.rows,
    Plan_List: state.Plans?.Planfeature?.rows,
    Feature_DataList: state.Feature_Master.Feature_Master_List?.rows,
});

export default connect(mapStateToProps, { getProductPlanList, getPlanFeature, getAll_Feature, deletePlanFeature, CreatePlanFeature })(Plans);

