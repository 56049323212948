import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class OrgPlanService {
    constructor() {
        this.headers = createHeaders();
    }

    async CreateOrgPlan(object) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_plan`;
            const response = await axios.post(url,object,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }



    async getPlanByOrgId(id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_plan_byOrg/${id}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async UpdatePlan(object) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/user_plan`;
            const response = await axios.put(url,object, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }
    

}


export default new OrgPlanService();