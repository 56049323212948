
import { GET_ALL_FEATURE , 
    DELETE_FEATURE,   
    CREATE_FEATURE,   
    UPDATE_FEATURE,   
    GET_FEATURE_BY_ID, } from "./types";
import FeatureMasterService from "../../services/feature_master.service";

export const getAll_Feature = () => async(dispatch) => {
    try {
      let res = await FeatureMasterService.getAllFeatureList();
       if(res.status === 200){
       
        dispatch({
          type : GET_ALL_FEATURE,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };

  export const deleteFeature = (id) => async(dispatch) => {
    try {
      let res = await FeatureMasterService.deleteFeature(id);
       if(res.status === 200){
       
        dispatch({
          type :DELETE_FEATURE,
          payload : res.data
        })
       }
    } catch (error) {
      console.error("error in login", error)
    }
  };
 