import { GET_ALL_ORG,GET_ORG_BY_ID } from "../actions/types";

const initialState = {
  Org_List: null,
  Org_data:null,
  
};

const Orgs = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case GET_ALL_ORG:
        return {
            ...state,
            Org_List: payload,
        };
    case GET_ORG_BY_ID:
        return {
            ...state,
            Org_data: payload,
        };
        
        default:
          return state;
  }
};

export default Orgs;
