import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import {
    Card, CardContent, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, TextField,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    IconButton, Divider, Checkbox
} from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { getProductPlanList } from '../redux/actions/product_plan';
import { getFeaturebyPlan, } from '../redux/actions/plans_feature';
import { getAll_Feature } from '../redux/actions/feature_master';
import { get_Org_by_Id, getAllOrgs } from '../redux/actions/org';
import { getOrgPLanByOrgId, CreateOrgPlan, UpgradeOrgPlan } from '../redux/actions/user_plan';
import { CreateOrgFeature, GetOrgFeature, deleteOrgFeature, UpdateOrgFeature } from '../redux/actions/user_feature';
import OrgPlanService from '../services/user_plan.service';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}><Typography>{children}</Typography></Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const OrgPlan = ({ getProductPlanList, Product_List, getFeaturebyPlan, Feature_Plan_List,
    getAll_Feature, AllFeatureList, get_Org_by_Id, getAllOrgs, ListOfOrgs, OrgData, CreateOrgFeature, getOrgPLanByOrgId, Org_PLan, New_PLan, GetOrgFeature, Org_Feature, deleteOrgFeature, UpgradeOrgPlan, UpdateOrgFeature }) => {



    const [Orglist, setOrglist] = useState([]);
    const [CurrentOrgPlan, setCurrentOrgPlan] = useState("No Plan Created");
    const [OrgFeatureList, setOrgFeatureList] = useState([]);
    const [Planlist, setPlanlist] = useState([]);
    const [PlanName, setPlanName] = useState();
    const [open, setOpen] = useState(false);
    const [Isopen, setIsOpen] = useState(false);
    const [Plan_FeatureList, setPlan_FeatureList] = useState([]);
    const [AllFeature, setAllFeature] = useState([]);
    const [Filtered_Feature, setFiltered_Feature] = useState([]);
    const [selectedFeatures, setSelectedFeatures] = useState([]);
    const [selectedOrg, setselectedOrg] = useState('select');
    const [selectedPlan, setselectedPlan] = useState('select');
    const [featureValues, setFeatureValues] = useState({});
    const [featureErrors, setFeatureErrors] = useState({});
    const [EditFeature, setEditFeature] = useState(null);
    const [newValue, setNewValue] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const user = JSON.parse(localStorage.getItem("authentication"))["id"]


    useEffect(() => {
        getProductPlanList();
        getAll_Feature();
        getAllOrgs();
        GetOrgFeature();

    }, []);

    const handleSelectChange = (event) => {
        setselectedOrg(event.target.value);
    };

    const handlePlanChange = (event) => {
        setselectedPlan(event.target.value);
    };

    useEffect(() => {
        if (ListOfOrgs) {
            setOrglist(ListOfOrgs);
        }
    }, [ListOfOrgs])

    useEffect(() => {
        if (Org_Feature) {
            setOrgFeatureList(Org_Feature);
            
        }
    }, [Org_Feature])


    useEffect(() => {
        if (Product_List) {
            setPlanlist(Product_List);
        }
    }, [Product_List])


    useEffect(() => {
        if (Feature_Plan_List) {
            setPlan_FeatureList(Feature_Plan_List);

        }
    }, [Feature_Plan_List])



    useEffect(() => {
        if (selectedOrg !== 'select' && selectedOrg !== '') {
            getOrgPLanByOrgId(selectedOrg).then((res) => {
            }).catch((err) => {
                console.error(err);
            });

        }
    }, [selectedOrg])


    useEffect(() => {
        if (Org_PLan) {
            setCurrentOrgPlan(Org_PLan);
        }
    }, [Org_PLan])


    useEffect(() => {
        if (selectedPlan && selectedPlan !== 'select') {
            const obj = Planlist.find(item => item.id === selectedPlan);
            setPlanName(obj.name);
            getFeaturebyPlan(selectedPlan)
        }
    }, [selectedPlan]);

    useEffect(() => {
        if (AllFeatureList) {
            setAllFeature(AllFeatureList);
        }
    }, [AllFeatureList])

    // Create a plan if No plan Exists ------Start
    const CreatePlan = () => {
        const plan = Planlist.find((item) => item.id === selectedPlan);
        const obj = {
            user_id: user,
            plan_id: plan.id,
            remarks: plan.description,
            is_active: plan.is_active,
            org_id: selectedOrg
        }
        OrgPlanService.CreateOrgPlan(obj).then((res) => {
            for (let item of Plan_FeatureList) {
                const object = {
                    user_id: user,
                    plan_id: res?.data?.data.id,
                    feature_id: item.feature_id,
                    remarks: item.description,
                    is_active: item.is_active,
                    value: item.value
                }
                CreateOrgFeature(object).then((res) => {
                    console.log("Object Created")
                }).catch((err) => {
                    console.error(err);
                })
            }
            

        }).catch((err) => {
            console.error(err);
        })
        setCurrentOrgPlan(Org_PLan)
        getProductPlanList();
        getAll_Feature();
        getAllOrgs();
        GetOrgFeature();
    }
    // Create a plan if No plan Exists ------End

    useEffect(()=>{
        if(OrgFeatureList?.length>0){
            console.log("org feature data",OrgFeatureList)
            let data=[]
            for(let item of OrgFeatureList ){
                console.log("obj",item)
                    let obj={
                    name: item.name,
                    value:item.value === ""?0:item.value
                    }
                    
                    data.push(obj);
            }
          localStorage.setItem("Feature",JSON.stringify(data));
        }
        

    },[OrgFeatureList])

    

    const UpgradePlan = () => {

        if (CurrentOrgPlan[0]?.plan_id !== selectedPlan) {
            const upgrade = Planlist.find((item) => item.id === selectedPlan);
            const obj = {
                id: CurrentOrgPlan[0]?.id,
                plan_id: selectedPlan,
                remarks: upgrade?.description,
                is_active: upgrade?.is_active
            }
            UpgradeOrgPlan(obj).then((res)=>{
                console.log("updated")
            }).catch((err)=>{
                console.error(err)
            });
        }

        const commonFeaturesMap = new Map(
            Plan_FeatureList.map(item => [item.feature_id, item.value])
        );

        OrgFeatureList.forEach(item => {
            const newValue = commonFeaturesMap.get(item.feature_id);

            if (newValue !== undefined && item.value !== newValue) {
                const updatedObject = {
                    id: item.id,
                    plan_id: item.user_plan_id,
                    feature_id: item.feature_id,
                    value: newValue,
                    is_active: item.is_active
                };
                console.log("updated", updatedObject)
                UpdateOrgFeature(updatedObject).then((res)=>{
                    console.log("updated")
                }).catch((err)=>{
                    console.log(err);
                });
            }
        });


        const orgFeatureIdsSet = new Set(OrgFeatureList.map(item => item.feature_id));

        const uniqueToPlanFeatures = Plan_FeatureList.filter(item => !orgFeatureIdsSet.has(item.feature_id));

        uniqueToPlanFeatures.forEach(item => {
            const object = {
                user_id: user,
                plan_id: CurrentOrgPlan[0]?.id,
                feature_id: item.feature_id,
                remarks: item.description,
                is_active: item.is_active,
                value: item.value
            };

            CreateOrgFeature(object)
            
        });
        
        getProductPlanList();
        getAll_Feature();
        getAllOrgs();
        GetOrgFeature();
    }

    useEffect(() => {
        const filterData = AllFeature.filter(
            (item) => !Plan_FeatureList.some(feature => feature.feature_id === item.id));
        setFiltered_Feature(filterData);

    }, [AllFeature, Plan_FeatureList])



    const handelOpenModal = () => {

        setSelectedFeatures([]);
        setOpen(true);
    };

    const handel_delete_feature = (feature) => {
        setPlan_FeatureList(prevList => prevList.filter(item => item.id !== feature.id));
    }

    const handleAddSelectedFeatures = () => {

        const obj = [];
        let idCounter = 1; // Start from 1

        for (let item of selectedFeatures) {
            const object = {
                id: idCounter++, // Incrementing counter for each object
                plan_id: selectedPlan,
                feature_id: item.id,
                plan_name: PlanName,
                feature_name: item.name,
                description: item.description,
                is_active: item.is_active,
                value: featureValues?.[item.id] || "",
                u_name: item.u_name,
            };

            obj.push(object);
        }
        if (obj.length > 0) {
            setPlan_FeatureList(prevList => [...prevList, ...obj]);
        }
        setOpen(false);
        setSelectedFeatures([]);
        setFeatureValues({});
    };


    const handleCheckboxChange = (feature) => {
        setSelectedFeatures((prev) =>
            prev.some(f => f.id === feature.id)
                ? prev.filter(f => f.id !== feature.id)
                : [...prev, feature]
        );
        setFeatureValues((prev) => {
            if (prev[feature.id]) {
                const updatedValues = { ...prev };
                delete updatedValues[feature.id];
                return updatedValues;
            }
            return prev;
        });
    };

    const handleFeatureValueChange = (id, value) => {
        const numericValue = Number(value);

        setFeatureValues((prev) => ({
            ...prev,
            [id]: value // Allow empty value temporarily
        }));

        setFeatureErrors((prev) => ({
            ...prev,
            [id]: value === "" || numericValue < 1 ? "Value should be greater than 0" : "" // Error if empty or <1
        }));
    };
    const isAddButtonDisabled = selectedFeatures.some(f => featureErrors[f.id]);


    const handleEditClick = (feature) => {
        setEditFeature(feature);
        setNewValue(feature.value);
        setErrorMessage("");
        setIsOpen(true);
    };

    const handleValue = (e) => {
        const value = e.target.value;
        setNewValue(value);

        if (value <= 0) {
            setErrorMessage("Value must be greater than 0.");
        } else {
            setErrorMessage(""); // Clear error message if value is valid
        }
    };


    const handleSave = () => {
        if (EditFeature && newValue !== "") {

            // Find the feature
            const feature = Plan_FeatureList.find(feature => feature.id === EditFeature.id);

            if (feature) {
                // Update a specific field, for example, 'name'
                feature.value = newValue; // Change 'name' to the field you want to update
            }
        }

        setIsOpen(false);
    };


    // Delete the Feature From Org
    const handelOrgFeature = (feature) => {
        if (feature) {
            deleteOrgFeature(feature.id).then((res) => {
                GetOrgFeature();
            }).catch((err) => {
                console.error(err);
            })
        }
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h5">Plans and Features</Typography>
                </CardContent>
                <Divider />
                <CardContent>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item xs={4} sm={2}>
                            <Typography variant="subtitle2" fontSize={14}>
                                Orgnisation:
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sm={4}>
                            <Typography variant="subtitle2" fontSize={14}>
                                <Select className="form-control" onChange={handleSelectChange} value={selectedOrg}  >
                                    <MenuItem value="select" key="" disabled="true">Select Org</MenuItem>
                                    {Orglist?.map((option, index) => (
                                        <MenuItem value={option?.o_id} key={index}>{option?.o_name}</MenuItem>
                                    ))}
                                </Select>
                            </Typography>
                        </Grid>

                        {selectedOrg !== 'select' && selectedOrg !== '' && (
                            <>
                                <Grid item xs={4} sm={2}>
                                    <Typography variant="subtitle2" fontSize={14}>
                                        Current  Plan:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8} sm={4}>
                                    <TextField
                                        className="form-control"
                                        value={CurrentOrgPlan[0]?.name}
                                        placeholder="No Plan Created"
                                        variant="outlined"
                                        fullWidth
                                        disabled
                                    />
                                </Grid>

                                <Grid container spacing={2} sx={{ p: 2 }}>


                            <Grid item xs={4} sm={2}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    {CurrentOrgPlan[0]?.name ? "Upgrade Plan" : "Select New Plan"}:
                                </Typography>
                            </Grid>
                            <Grid item xs={8} sm={4}>
                                <Typography variant="subtitle2" fontSize={14}>
                                    <Select className="form-control" onChange={handlePlanChange} value={selectedPlan}  >
                                        <MenuItem value="select" key="" disabled="true">Select Plan</MenuItem>
                                        {Planlist?.map((option, index) => (
                                            <MenuItem value={option?.id} key={index}>{option?.name}</MenuItem>
                                        ))}
                                    </Select>
                                </Typography>
                            </Grid>
                        </Grid>
                            </>

                            
                        )}

                    </Grid>

                    <Divider />
                </CardContent>


                <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
                    <DialogTitle>Available Features to Add</DialogTitle>
                    <DialogContent>
                        <TableContainer >
                            <Table >
                                <TableHead>
                                    <TableRow>
                                        <TableCell >Select</TableCell>
                                        <TableCell >Feature</TableCell>

                                        <TableCell >Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Filtered_Feature?.map((feature, index) => (
                                        <TableRow key={index}>

                                            <TableCell >
                                                <Checkbox
                                                    checked={selectedFeatures.some(f => f.id === feature.id)}
                                                    onChange={() => handleCheckboxChange(feature)}
                                                />
                                            </TableCell>
                                            <TableCell >{feature.name}</TableCell>
                                            <TableCell>
                                                {feature.is_value && (

                                                    <TextField
                                                        type="number"
                                                        variant="outlined"
                                                        value={featureValues[feature.id] || ""}
                                                        onChange={(e) => handleFeatureValueChange(feature.id, e.target.value)}
                                                        placeholder="Add Value"
                                                        disabled={!selectedFeatures.some(f => f.id === feature.id)}
                                                        error={!!featureErrors[feature.id]} // Show error styling if there's an error
                                                        helperText={featureErrors[feature.id] || ""} // Show error message
                                                    />

                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="secondary">Cancel</Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddSelectedFeatures}
                            disabled={isAddButtonDisabled || selectedFeatures.length === 0}
                        >
                            Add Selected Features
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={Isopen} onClose={() => setIsOpen(false)} >
                    <DialogTitle>
                        <Typography variant="subtitle2" fontSize={14}>
                            Edit Number of {EditFeature?.feature_name}:
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <br />
                        <TextField
                            fullWidth
                            label="Value"
                            value={newValue}
                            onChange={handleValue}
                            error={!!errorMessage}  // Show error style if errorMessage exists
                            helperText={errorMessage}  // Display the error message under the field
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsOpen(false)} color="secondary">Cancel</Button>
                        <Button onClick={handleSave} color="primary" disabled={!!errorMessage}>Save</Button>
                    </DialogActions>
                </Dialog>

            </Card>
            <br />
            {selectedOrg !== 'select' && selectedOrg !== '' ?
                <Card>

                    <CardContent>
                        <Typography variant="h5">Org Features List</Typography>
                    </CardContent>
                    <Divider />
                    {/**  List of Organisation Feature  **/}

                    {CurrentOrgPlan[0]?.name && OrgFeatureList?.length > 0 ?
                        <CardContent>

                            <Grid container spacing={2}>

                                {OrgFeatureList?.length > 0 ? (
                                    OrgFeatureList.map((feature, idx) => (
                                        <Grid item xs={12} sm={6} md={4} key={idx}>
                                            <Box
                                                sx={{
                                                    padding: 2,
                                                    border: "1px solid #ddd",
                                                    borderRadius: "4px",
                                                    boxShadow: 1,
                                                    textAlign: "center",
                                                    backgroundColor: "#f9f9f9",
                                                    position: "relative",
                                                }}
                                            >
                                                {/* Close Icon */}
                                                <IconButton
                                                    sx={{ position: "absolute", top: "4px", right: "30px", zIndex: 1 }}
                                                    size="small"
                                                    onClick={() => handelOrgFeature(feature)}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>

                                                {/* Edit Icon */}
                                                {/* {feature.value &&
                                                    <IconButton
                                                        sx={{ position: "absolute", top: "4px", right: "4px", zIndex: 1 }}
                                                        size="small"
                                                        onClick={() => handleEditClick(feature)}
                                                    >
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>} */}


                                                <Typography color="textPrimary">
                                                    {feature.name} {feature.value ? `- ${feature.value}` : ""}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography>No features available.</Typography>
                                )}
                            </Grid>
                        </CardContent>
                        :
                        <CardContent>
                            <Typography >No Plan Created Yet....</Typography>
                            <Typography >Please Select Plan from Below </Typography>
                        </CardContent>

                    }

                    {/**  List of Organisation Feature End  **/}

                    {/**  List of All Feature **/}

                    <CardContent>
                        <Divider />
                        

                        {selectedPlan !== 'select' && selectedPlan !== '' &&

                            <TabPanel>
                                <Card sx={{ width: "100%", padding: 2, boxShadow: 3 }}>
                                    <CardContent>
                                        <Grid container spacing={2}>

                                            {Plan_FeatureList?.length > 0 ? (
                                                Plan_FeatureList.map((feature, idx) => (
                                                    <Grid item xs={12} sm={6} md={4} key={idx}>
                                                        <Box
                                                            sx={{
                                                                padding: 2,
                                                                border: "1px solid #ddd",
                                                                borderRadius: "4px",
                                                                boxShadow: 1,
                                                                textAlign: "center",
                                                                backgroundColor: "#f9f9f9",
                                                                position: "relative",
                                                            }}
                                                        >
                                                            {/* Close Icon */}
                                                            <IconButton
                                                                sx={{ position: "absolute", top: "4px", right: "30px", zIndex: 1 }}
                                                                size="small"
                                                                onClick={() => handel_delete_feature(feature)}
                                                            >
                                                                <CloseIcon fontSize="small" />
                                                            </IconButton>

                                                            {/* Edit Icon */}
                                                            {feature.value &&
                                                                <IconButton
                                                                    sx={{ position: "absolute", top: "4px", right: "4px", zIndex: 1 }}
                                                                    size="small"
                                                                    onClick={() => handleEditClick(feature)}
                                                                >
                                                                    <EditIcon fontSize="small" />
                                                                </IconButton>}


                                                            <Typography color="textPrimary">
                                                                {feature.feature_name} {feature.value ? `- ${feature.value}` : ""}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                ))
                                            ) : (
                                                <Typography>No features available.</Typography>
                                            )}
                                        </Grid>
                                    </CardContent>
                                </Card>
                                <Divider />
                                <Button color="primary" variant="text" onClick={handelOpenModal}>
                                    +Add Feature
                                </Button>
                                {OrgFeatureList?.length > 0 ?
                                    <Button color="primary" variant="text" onClick={() => UpgradePlan()}>
                                        Upgrade Plan
                                    </Button> : <Button color="primary" variant="text" onClick={() => CreatePlan()}>
                                        + Create Plan
                                    </Button>
                                }

                            </TabPanel>

                        }


                    </CardContent>

                    {/**  List of All Feature End  **/}

                </Card>
                : null
            }
        </>
    );
};

const mapStateToProps = (state) => ({
    Product_List: state?.Product_Plans?.Product_List?.rows,
    Plan_List: state.Plans?.Planfeature?.rows,
    Feature_Plan_List: state.Plans?.Feature_by_Plan?.rows,
    AllFeatureList: state.Feature_Master.Feature_Master_List?.rows,
    ListOfOrgs: state.Orgs.Org_List?.rows,
    OrgData: state.Orgs.Org_data?.rows,
    Org_PLan: state.OrgPlan?.Org_PlanByOrg_Id,
    New_PLan: state.OrgPlan?.Create_Org?.rows,
    Org_Feature: state.OrgFeature?.OrgFeatureList?.rows
});

export default connect(mapStateToProps,
    {
        getProductPlanList, getFeaturebyPlan,
        getAll_Feature, get_Org_by_Id, getAllOrgs, getOrgPLanByOrgId, CreateOrgPlan,
        CreateOrgFeature, GetOrgFeature, deleteOrgFeature, UpgradeOrgPlan, UpdateOrgFeature
    })(OrgPlan);

