import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import loader from "./loader";
import runJob from "./runJob";
import suites from "./suites";
import template from "./template";
import exportImport from "./exportImport"
import testCaseReport  from "./testCaseReport";
import suiteReport from "./suiteReport";
import manageuser from "./manageuser";
import assignUsers from "./assignUsers";
import profile from "./profile";
import cronTab from "./crontab";
import clocked from "./clocked";
import dashboard from "./dashboard";
import suiteExecution from "./executionReport";
import schedular from "./schedular";
import files from './files';
import SchedularJob from "./schedularReport";
import notification from "./notification";
import jira from "./jira";
import jiraToken from "./jiraToken";
import Enviroment from "./enviroment";
import assignTestCase from "./assignTestCase";
import Enviroment_Persona from "./environmentPersona";
import aiTest from "./aiTest";
import Plans from "./plans"
import Product_Plans from "./product_plans"
import Feature_Master from "./feature_master"
import Orgs from "./orgs"
import OrgPlan from "./user_plan"
import OrgFeature from "./user_feature"

export default combineReducers({
  auth,
  message,
  loader,
  runJob,
  suites,
  template,
  exportImport,
  testCaseReport,
  suiteReport,
  manageuser,
  assignUsers,
  profile,
  cronTab,
  clocked,
  dashboard,
  suiteExecution,
  schedular,
  files,
  SchedularJob,
  notification,
  jira,
  jiraToken,
  Enviroment,
  assignTestCase,
  Enviroment_Persona,
  aiTest,
  Plans,
  Product_Plans,
  Feature_Master,
  Orgs,
  OrgPlan,
  OrgFeature,
});
