import { GET_ORG_PLAN_BY_ORG,CREATE_ORG_PLAN,UPDATE_ORG_PLAN } from "../actions/types";

const initialState = {
  Org_PlanByOrg_Id: null,
  Create_Org:null,
  
};

const OrgPlan = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case GET_ORG_PLAN_BY_ORG:
        return {
            ...state,
            Org_PlanByOrg_Id: payload,
        };
    case CREATE_ORG_PLAN:
        return {
            ...state,
            Create_Org: payload,
        };
    case UPDATE_ORG_PLAN:
        return {
            ...state,
            isUpdated: true,
        };
        
        default:
          return state;
  }
};

export default OrgPlan;
