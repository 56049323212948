import { GET_PRODUCT_PLAN,CREATE_PRODUCT_PLAN,GET_FEATURE_BY_PLAN } from "../actions/types";

const initialState = {
  Planfeature: null,
  Feature_by_Plan:null,
  
};

const Plans = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case GET_PRODUCT_PLAN:
        return {
            ...state,
            Planfeature: payload,
        };
    case CREATE_PRODUCT_PLAN:
        return {
            ...state,
            isCreated: true,
        };
    case GET_FEATURE_BY_PLAN:
        return {
            ...state,
            Feature_by_Plan: payload,
        };
        
        default:
          return state;
  }
};

export default Plans;
