import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class testStepsService {
    constructor() {
        this.headers = createHeaders();
    }

async createNewTestStep(obj) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/test_step`;
        const response = await axios.post(url,obj,this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async createconditionsteps(obj) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/createconditionsteps`;
        const response = await axios.post(url,obj,this.headers);
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async updateTestStep(obj) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/test_step`;
        const response = await axios.put(url,obj,this.headers);       
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

async getTestStepById(testId) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/get_stepby_testid/${testId}`;
        const response = await axios.get(url, this.headers);      
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async get_stepby_trefstepid(stepid) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/get_stepby_trefstepid/${stepid}`;
        const response = await axios.get(url, this.headers);      
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async updateFile(obj) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/test_step_addfile`;
        const response = await axios.put(url, obj ,this.headers);      
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async addUseData(obj) {
    try {  
        const url = `${ENV_VAR.API_HOST}/api/v1/test_step_addusedata`;
        const response = await axios.put(url, obj ,this.headers);      
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async deleteTestStepById(testId) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/test_step/${testId}`;
        const obj = {
            id: testId
        }
        const response = await axios.delete(url, 
               {
                id: testId
               }, this.headers);      
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }

  async getstepsforcondition(priority,testId) {
    try {
        const url = `${ENV_VAR.API_HOST}/api/v1/Getstepsforcondition/${priority}/${testId}`;
        const response = await axios.get(url, this.headers);      
        return response;
    } 
    catch (error) {
        throw error.response.data.error;
    }
  }
}

export default new testStepsService();