import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class ProductPlanService {
    constructor() {
        this.headers = createHeaders();
    }

    async getAllProductPlan() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/plans`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


}


export default new ProductPlanService();