import { useState ,useEffect } from "react";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { BrowserRouter } from "react-router-dom"; 
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPTheme from "./styles/getLPTheme";
import theme2 from "./styles/theme2";
import { getCurrentUser} from './redux/actions/profile';
import Sidebar from "./layouts/Sidebar";
import Header from './layouts/Header';
import {Box} from '@mui/material';
import { connect } from "react-redux";
import {setAuthToken} from './middleware/setAuthToken';
import Spinner from "react-spinkit";
import { ToastContainer } from 'react-toastify';
import { getProjectsapp, } from "./redux/actions/auth";
import {GetOrgFeatureByOrg} from "./redux/actions/user_feature"

// import { getNotificationList } from "./redux/actions/notification";
// import NotificationService from './services/notification.service';
// import { useDispatch , useSelector} from "react-redux";





const initialState = {
  isAuthenticated : false
};

export function setAuth() {
  if (typeof localStorage !== 'undefined') {
    const storedData = JSON.parse(localStorage.getItem('authentication'));
    if(storedData.access_token){
      initialState.isAuthenticated = true;
    }
   };
   return initialState.isAuthenticated
}

 export function App({auth, isLoading,getNotificationList,NotificationList,currentUserInfo,getProjectsapp, getCurrentUser,DataList,GetOrgFeatureByOrg,Current_Org_Feature}) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("light");
  // const LPtheme1 = createTheme(getLPTheme(mode));
  // const LPtheme2 = createTheme(theme2(mode));
  // const [LPtheme, setTheme] = useState(LPtheme1);
  const [ProjName, setProjName] = useState('');
  const [dropdownList, setDropdownList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("select");
  const [isOpen,IsOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [mainLayout, setMainLayout] = useState({
    path: "/dashboard",
    label: "Dashboard",
  });

 // auth.isLoggedIn = false ;
  const user = JSON.parse(localStorage.getItem("authentication"))
  useEffect(() => {
    if (localStorage.getItem("token") !== null && localStorage.getItem("token").length > 0) {
      setAuthToken(localStorage.getItem("token"));
      setIsAuthenticated(true);
      //alert("Token", )
    }
  }, [localStorage.getItem("token")]);


  useEffect(() => {
    // getNotificationList()
    getCurrentUser()
  }, []);
  useEffect(() => {
    // getNotificationList()
    if(isAuthenticated) {
      getProjectsapp(0, 0, null, null, JSON.stringify({}))
    }
  }, [isAuthenticated]);


  useEffect(()=>{
    if(user?.proj_id){
      setSelectedValue(user?.proj_id)
      const proj = DataList?.rows?.find((item) => item?.proj_id === user?.proj_id)
      setProjName(proj?.proj_name)
    }
  },[user,DataList?.rows])

  useEffect(() => {
    if (user?.default_org_fk) {
        GetOrgFeatureByOrg(user.default_org_fk)
            .then((res) => {
                console.log("API called");
            })
            .catch((err) => {
                console.error(err);
            });
    }
}, [user?.default_org_fk]);  // Added dependency

  
  useEffect(()=>{
    if(Current_Org_Feature?.length>0){
      let data=[]
      for(let item of Current_Org_Feature ){
            let obj={
              name: item.metadata,
              value:item.feature_value === ""?0:item.feature_value
            }
            data.push(obj);
      }
      localStorage.setItem("Feature",JSON.stringify(data));
    }
  },[Current_Org_Feature])

  useEffect(()=>{
    if (DataList?.rows?.length > 0) {
      const values = DataList?.rows?.map(item => ({
        value: item.proj_id,
        label: item.proj_name,
      }));
      setDropdownList(values);
      IsOpen(true)

    } else{
      IsOpen(false)
    }

  },[DataList,isOpen])

  const LPtheme = createTheme(getLPTheme(mode));
  const toggleColorMode = () => {
    // setTheme((prev) => (prev === LPtheme1 ? LPtheme1 : LPtheme2));
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const handleChangeProj = (event) => {
    setSelectedValue(event.target.value);
    const proj = DataList?.rows?.find((item) => item?.proj_id === event.target.value)
    const user = JSON.parse(localStorage.getItem("authentication"));
    user.proj_id = event.target.value;
    user.proj_name=proj?.proj_name;
    localStorage.setItem('authentication', JSON.stringify(user));
    setProjName(proj?.proj_name)

  };
  const RestProj =()=>{
    setSelectedValue("select")
    const user = JSON.parse(localStorage.getItem("authentication"));
    user.proj_id=null;
    user.proj_name=null;
    localStorage.setItem('authentication', JSON.stringify(user));
    setProjName('')
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
   <>
   {isLoading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(16, 16, 16, 0.5)",
            zIndex: 999990,
          }}
        >
          <Spinner
            name="three-bounce"
            style={{
              marginTop: "50vh",
              marginLeft: "50vw",
              zIndex: 999999,
              color: "#0959AA",
            }}
          />
        </div>
      ) : (
        ""
      )}
      <ThemeProvider theme={LPtheme}>
        <CssBaseline />
        <Box sx={{ display: "flex" }}>
          <BrowserRouter>
          {isAuthenticated && isAuthenticated ? (
            <>
              <Header
                mode={mode}
                toggleColorMode={toggleColorMode}
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                DataList={dropdownList}
                handleChangeProj={handleChangeProj}
                selectedValue={selectedValue}
                ProjName={ProjName}
                isOpen={isOpen}
                RestProj={RestProj}
                // NotificationList={jobList}
                // MarkAsRead={MarkAsRead}
                
              />
            </>
          ) : (
            ""
          )}
            {isAuthenticated && isAuthenticated ? <>
                <Sidebar
                  open={open}
                  handleDrawerClose={handleDrawerClose}
                  setMainLayout={setMainLayout}
                  mainLayout={mainLayout}
                />
                <PrivateRoutes open={open} />
              </> : 
              <PublicRoutes/>}
          </BrowserRouter>
        </Box>
      </ThemeProvider>
      <ToastContainer autoClose={2000}/>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  isLoading: state.loader.isLoading,
  // NotificationList:state.notification?.notification,
  currentUserInfo: state.profile.userDetails,
  DataList:state.auth.listProjectapp,
  Current_Org_Feature:state.OrgFeature?.Current_Org_Feature?.rows
});

const mapDispatchToProps = {
  getCurrentUser,
  getProjectsapp,
  GetOrgFeatureByOrg
  // getNotificationList,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
