import axios from "axios";
import ENV_VAR from "../common";
import { createHeaders } from "./common/headers";

class PlanFeatureService {
    constructor() {
        this.headers = createHeaders();
    }

    async AddPlanFeature(object) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/plan_feature`;
            const response = await axios.post(url,object,this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }



    async getPlanFeatureList() {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/plan_feature`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async getPlanFeatureByProduct(id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/planwisefeature/${id}`;
            const response = await axios.get(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }

    async deletePlanFeature(id) {
        try {
            const url = `${ENV_VAR.API_HOST}/api/v1/plan_feature/${id}`;
            const response = await axios.delete(url, this.headers);
            return response;
        }
        catch (error) {
            throw error.response.data.error;
        }
    }


}


export default new PlanFeatureService();