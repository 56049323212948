import {  CREATE_ORG_FEATURE,GET_ORG_FEATURE,DELETE_ORG_FEATURE,UPDATE_ORG_FEATURE,GET_ORG_FEATURE_BY_ORG } from "./types";
import OrgFeatureService from "../../services/user_feature.service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CreateOrgFeature = (object) => async(dispatch) => {
  try {
    let res = await OrgFeatureService.CreateOrgFeature(object);
     if(res.status === 200){
      dispatch({
        type : CREATE_ORG_FEATURE,
        payload : res.data
      })
     }
  } catch (error) {
    console.error("error", error)
  }
};

export const GetOrgFeature = () => async(dispatch) => {
  try {
    let res = await OrgFeatureService.getOrgFeature();
     if(res.status === 200){
      dispatch({
        type : GET_ORG_FEATURE,
        payload : res.data
      })
     }
  } catch (error) {
    console.error("error", error)
  }
};

export const GetOrgFeatureByOrg = (id) => async(dispatch) => {
  try {
    let res = await OrgFeatureService.getOrgFeaturebyOrg(id);
     if(res.status === 200){
      dispatch({
        type : GET_ORG_FEATURE_BY_ORG,
        payload : res.data
      })
     }
  } catch (error) {
    console.error("error", error)
  }
};



export const deleteOrgFeature = (id) => async(dispatch) => {
    try {
      let res = await OrgFeatureService.deleteOrgFeature(id);
       if(res.status === 200){
         dispatch({
        type : DELETE_ORG_FEATURE,
        payload : res.data
      })
       }

    } catch (error) {
      toast.error("Error While Removing")
      console.error("error", error)
    }
  };

export const UpdateOrgFeature = (object) => async(dispatch) => {
    try {
      let res = await OrgFeatureService.UpdateOrgFeature(object);
       if(res.status === 200){
         dispatch({
        type : UPDATE_ORG_FEATURE,
        payload : res.data
      })
       }

    } catch (error) {
      toast.error("Error While Removing")
      console.error("error", error)
    }
  };


 